.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.password-generator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
}

label {
  font-weight: bold;
  margin-right: 10px;
}

button {
  margin: 20px 0;
  padding: 10px 20px;
  background-color: #0077ff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

button:hover {
  background-color: blue;
}

.password-container {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 20px;
  padding: 0 10px;
}

.password-container input[type="text"] {
  font-size: 1.5rem;
  padding: 0.5rem;
  margin-bottom: 1rem;
  text-align: center;
  border: none;
  background-color: #f7f7f7;
  border-radius: 0.25rem;
  width: 25rem;
  max-width: 100%;
}

.password-container button {
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border: none;
  background-color: #0077ff;
  color: #fff;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.password-container button:hover {
  background-color: #005dff;
}

.copy-icon {
  margin-left: 10px;
  color: #007bff;
  cursor: pointer;
}
body {
  background-color: #CAEDF7;
}